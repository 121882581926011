<template>
    <v-container fluid v-if="selectedLocation">
        <v-row>
            <v-col md="3">
                <v-sheet color="#92D050" class="pa-2 rounded-xl fill-height">
                    <v-card color="#0E0856" class="rounded-xl">
                        <v-card-title>
                            Energy Source
                        </v-card-title>
                        <p class="pl-4 text-overline" v-if="branchName">Branch: <strong>{{
                            branchName['device.name']['value'] }}</strong></p>
                        <v-card-text class="d-flex justify-space-around">
                            <v-sheet height="75" width="125"
                                :class="[isGridActive ? 'green-background' : 'red-background', 'rounded-lg']"
                                class="rounded-lg d-flex align-center justify-center">
                                <div class="d-flex flex-column align-center">
                                    <strong>GRID</strong>
                                    <v-icon color="#0E0856" class="mt-1" size="25">
                                        mdi-transmission-tower
                                    </v-icon>
                                </div>
                            </v-sheet>
                            <v-divider vertical>
                            </v-divider>
                            <v-sheet color="red" v-if="!isGenOneActive && !isGenTwoActive" height="75" width="125"
                                class="rounded-lg d-flex align-center justify-center">
                                <div class="ma-2 d-flex flex-column align-center">
                                    <strong>GEN OFF</strong>
                                    <v-icon class="mb-1" color="#0E0856" size="25">
                                        mdi-generator-stationary
                                    </v-icon>
                                </div>
                            </v-sheet>
                            <v-sheet v-if="isGenOneActive == true" height="75" width="125"
                                :class="[isGenOneActive ? 'green-background' : 'red-background', 'rounded-lg']"
                                class="rounded-lg d-flex align-center justify-center">
                                <div class="ma-2 d-flex flex-column align-center">
                                    <strong>GEN I</strong>
                                    <v-icon class="mb-1" color="#0E0856" size="25">
                                        mdi-generator-stationary
                                    </v-icon>
                                    {{ branch.gen_one_size }}kVa
                                </div>
                            </v-sheet>
                            <v-sheet v-if="isGenTwoActive == true" height="75" width="125"
                                :class="[isGenTwoActive ? 'green-background' : 'red-background', 'rounded-lg']"
                                class="rounded-lg d-flex align-center justify-center">
                                <div class="d-flex flex-column align-center">
                                    <strong>GEN II</strong>
                                    <v-icon class="mb-1" color="#0E0856" size="25">
                                        mdi-generator-stationary
                                    </v-icon>
                                    {{ branch.gen_two_size }}kVa
                                </div>
                            </v-sheet>
                        </v-card-text>
                    </v-card>
                    <!--Report Generation Dialog Start-->
                    <v-card color="#0E0856" class="d-none d-md-block mt-2 pa-2 rounded-xl">
                        <v-card-text>
                            Select Date Range
                            <a-range-picker class="mt-1" @change="onDateChange" />
                            <div class="d-flex justify-end">
                                <v-dialog max-width="1000" v-model="report" persistent>
                                    <template v-slot:activator="{ props: activatorProps }">
                                        <v-btn v-bind="activatorProps" outline color="#92D050" class="mt-3"
                                            size="small">Report</v-btn>
                                    </template>
                                    <v-card color="#0E0856" class="ma-2 rounded-xl">
                                        <v-card-title>
                                            <div class="d-flex justify-space-between align-center">
                                                <span class="text-h5">Generated Report</span>
                                                <v-spacer />
                                                <json-excel worksheet="Diesel Consumption Report" :fields="json_fields"
                                                    class="btn text-overline" type="xlsx" name="RMS_Report.xlsx"
                                                    :data="filteredMessages">Download <v-btn variant="text"
                                                        icon="mdi-download"></v-btn>
                                                </json-excel>
                                            </div>
                                        </v-card-title>
                                        <v-sheet>
                                            <v-table class="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th class="custom-header">Date</th>
                                                        <th class="custom-header">Gen Runtime <p class="text-caption">
                                                                (HH-MM-SS)</p>
                                                        </th>
                                                        <th class="custom-header">Grid Uptime <p class="text-caption">
                                                                (HH-MM-SS)</p>
                                                        </th>
                                                        <th class="custom-header">Diesel SoB <p class="text-caption">
                                                                (Liters)</p>
                                                        </th>
                                                        <th class="custom-header">Diesel CoB <p class="text-caption">
                                                                (Liters)</p>
                                                        </th>
                                                        <th class="custom-header">Refill <p class="text-caption">
                                                                (Liters)</p>
                                                        </th>
                                                        <th class="custom-header">Total Usage <p class="text-caption">
                                                                (Liters)</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="message in filteredMessages" :key="message.timestamp"
                                                        class="custom-row">
                                                        <td>{{ shortTimestamp(message.end) }}</td>
                                                        <td>{{ convertToHHMMSS(message.gen_combined) }}</td>
                                                        <td>{{ convertToHHMMSS(message.grid) }}</td>
                                                        <td>{{ message.SoB }}</td>
                                                        <td>{{ message.CoB }}</td>
                                                        <td>{{ message.refill?.level_change ?? 0 }}</td>
                                                        <td>{{ message.Diesel_Use }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-table>
                                        </v-sheet>
                                        <v-card-actions class="mr-3 pa-3">
                                            <v-spacer></v-spacer>
                                            <v-btn color="#92D050" @click="this.report = false">Close
                                                <v-icon>mdi-close-circle-outline</v-icon></v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </v-card-text>
                    </v-card>
                    <!--Report Generation Dialog Ends-->
                </v-sheet>
            </v-col>
            <v-col md="3">
                <v-sheet color="#92D050" class="pa-2 rounded-xl fill-height">
                    <v-card color="#0E0856" class="rounded-xl fill-height">
                        <v-card-title>
                            Daily Trends
                        </v-card-title>
                        <v-skeleton-loader color="#0E0856" v-if="loading" type="list-item-two-line"></v-skeleton-loader>
                        <v-card-text v-else-if="duration">
                            <div class="pb-3 text-caption">{{ formatTimestamp(duration.timestamp) }}</div>
                            <v-sheet class="rounded-lg">
                                <v-table class="custom-table">
                                    <thead>
                                        <tr>
                                            <th class="custom-header">
                                                Power Source
                                            </th>
                                            <th class="custom-header">
                                                Duration
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>GRID:</td>
                                            <td>{{ convertToHHMMSS(duration.grid) }}</td>
                                        </tr>
                                        <tr>
                                            <td>GEN:</td>
                                            <td>{{ convertToHHMMSS(duration['combined_gen']) }}</td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-sheet>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-col>
            <v-col md="3">
                <v-sheet color="#92D050" class="pa-2 rounded-xl fill-height">
                    <v-card color="#0E0856" class="rounded-xl fill-height">
                        <v-card-title class="ma-2 d-flex align-center">
                            Grid Activity
                        </v-card-title>
                        <v-skeleton-loader color="#0E0856" v-if="tankLoading"
                            type="list-item-two-line"></v-skeleton-loader>
                        <v-card-text v-else>
                            <v-sheet class="rounded-lg">
                                <v-table class="custom-table">
                                    <thead>
                                        <tr>
                                            <th class="custom-header">
                                                Phase
                                            </th>
                                            <th class="custom-header">
                                                kWh
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Voltage: </td>
                                            <td>{{ gridPowerTemplate() }}V</td>
                                        </tr>
                                        <tr>
                                            <td>Current:</td>
                                            <td>{{ gridVoltageTemplate() }}A</td>
                                        </tr>
                                        <tr>
                                            <td>Frequency:</td>
                                            <td>50HZ</td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-sheet>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-col>
            <v-col md="3">
                <!--Storage Tank -->
                <v-sheet color="#92D050" class="pa-2 rounded-xl fill-height">
                    <v-card color="#0E0856" class="rounded-xl">
                        <v-card-title class="d-flex align-center">
                            Storage Tank <v-spacer />
                            <v-dialog scrollable v-model="refillModal" max-width="500">
                                <template v-slot:activator="{ props: activatorProps }">
                                    <v-btn v-bind="activatorProps" icon variant="text">
                                        <v-badge :content="unreadCount" :value="unreadCount" color="success"
                                            v-if="unreadCount > 0" overlap>
                                            <v-icon color="red">mdi-bell-outline</v-icon>
                                        </v-badge>
                                    </v-btn>
                                </template>
                                <v-card color="#0E0856" class="rounded-xl">
                                    <v-card-title>
                                        <div class="pa-3 d-flex align-center justify-space-between">Refill
                                            Notification
                                            <v-icon color="success">mdi-storage-tank-outline</v-icon>
                                        </div>
                                    </v-card-title>
                                    <v-sheet min-height="50" class="pa-3">
                                        <v-list v-for="item in items" :key="item.id">
                                            <v-list-item> <span class="font-weight-bold text-green pa-2">
                                                +{{ item.level_change }}</span>
                                                    Liters was refilled on {{ formatTimestamp(item.end) }} <v-btn class="rounded-xl" variant="text" size="x-small">
                                                    <v-icon>mdi-eye</v-icon></v-btn>
                                                </v-list-item>
                                        </v-list>
                                    </v-sheet>
                                    <v-card-actions>
                                        <v-btn @click="refillModal = false" class="rounded-xl"
                                            size="small">Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-btn variant="text" density="comfortable" icon="mdi-cog"
                                @click="volume_setting = true"></v-btn>
                            <v-dialog max-width="450" v-model="volume_setting">
                                <v-card color="#0E0856" class="rounded-xl">
                                    <v-card-title>
                                        Set Low Diesel Notification
                                        <template v-slot:append>
                                            <v-icon>mdi-guage-low</v-icon>
                                        </template>
                                    </v-card-title>
                                    <v-sheet class="pa-3 align-center">
                                        <div>Drag to set the low diesel alert volume.</div>
                                        <v-slider color="#D4AF67" thumb-label v-model="slider" :min="100"  :max="branch.storage_size || 0" class="pa-3">                                            
                                        </v-slider>                                        
                                    </v-sheet>
                                    <v-card-actions><v-spacer></v-spacer>
                                        <v-btn color="error" class="rounded-xl"
                                        size="small">Close</v-btn>
                                        <v-btn class="rounded-xl"
                                        size="small">Save</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card-title>
                        <v-skeleton-loader color="#0E0856" v-if="tankLoading"
                            type="list-item-two-line"></v-skeleton-loader>
                        <TankView v-else :currentVolume="currentVolume" :totalCapacity="branch.storage_size || 0" />
                    </v-card>
                </v-sheet>
                <v-dialog v-model="Error_Notification">
                    <v-card class="rounded-xl">
                        <v-card-title>Decice Error</v-card-title>
                        <v-sheet :color="color">
                            {{errorText}}
                        </v-sheet>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn>Close</v-btn>
                            <v-btn>Resolved</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row class="d-none d-md-block">
            <v-col>
                <v-sheet color="#92D050" class="pa-5 rounded-xl fill-height">
                    <v-card class="rounded-lg">
                        <v-card-title>12 Hours Diesel Trends</v-card-title>
                        <v-card-text>
                            <apexchart type="line" height="350" :options="chartOptions" :series="series">
                            </apexchart>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import JsonExcel from 'vue-json-excel3'
import TankView from '@/components/Charts/TankView.vue';
import api from '@/services/api';
export default {
    components: {
        JsonExcel,
        TankView,
    },
    props: {
        selectedLocation: Object,
        deviceId: Object,
    },
    data() {
        return {
            drainData: [],
            drainCount: 0,
            errorText: '',
            deviceBattery: null,
            ExternalPower: null,
            slider: 0,          
            volume_setting: false,
            refillModal: false,
            branch: [],
            tankLoading: true,
            loading: true,
            tab: null,
            storageRefill: [],
            storageUnread: 0,
            currentVolume: 0, // default value            
            refillDialog: false,
            baseTankDialog: false,
            refill: null,
            unreadCount: 0,
            json_fields: {
                'Date': {
                    field: 'begin',
                    callback: (value) => {
                        return this.formatTimestamp(value)
                    }
                },
                'Grid Uptime': {
                    field: 'grid',
                    callback: (value) => {
                        return this.convertToHHMMSS(value)
                    }
                },
                'Gen 1 Runtime': {
                    field: 'gen_one',
                    callback: (value) => {
                        return this.convertToHHMMSS(value)
                    }
                },
                'Gen 2 Runtime': {
                    field: 'gen_two',
                    callback: (value) => {
                        return this.convertToHHMMSS(value)
                    }
                },
                'SoB (Liters)': 'SoB',
                'CoB (Liters)': 'CoB',
                'Diesel Consumption': 'Diesel_Use',
                'Refill (Liters)': {
                    field: 'refill',
                    callback: (value) => {
                        return `${value['level_change'] || 0}`;
                    }
                },
            },
            filteredMessages: [],
            report: false,
            reportPayload: [],
            selectedRange: [],
            messages: [],
            branchName: null,
            data: null,
            duration: null,
            series: [{
                name: 'Storage Tank',
                data: []
            },
            ],
            chartOptions: {
                chart: {
                    id: 'Diesel Consumption',
                    animations: {
                        enabled: true,
                        easing: 'linear',
                        dynamicAnimation: {
                            speed: 1000
                        }
                    },
                    toolbar: {
                        speed: 1000
                    },
                    zoom: {
                        enabled: true,
                    }
                },
                xaxis: {
                    type: 'datetime',
                    range: 4.32e+7,// 12 Hours
                    tickAmount: 24, // Adjust the number of ticks to fit 2-hour intervals
                    labels: {
                        format: 'HH:mm',
                    },

                },
                yaxis: {
                    min: 0,
                    max: 12000,
                    tickAmount: 10,
                    labels: {
                        formatter: (value) => `${value} liters`,
                    },
                    title: {
                        text: 'Diesel Volume',
                    },
                },
                tooltip: {
                    x: {
                        format: 'dd MMM HH:mm'
                    }
                },
                stroke: {
                    curve: 'smooth'
                }
            },
        }
    },
    watch: {
        
        selectedLocation: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.message) {
                    this.updateGraph();
                }
            }
        }
    },
    computed: {
        isGridActive() {
            return this.data ? this.data["din.1"] : false;
        },
        isGenOneActive() {
            return this.data ? this.data["din.2"] : false;
        },
        isGenTwoActive() {
            return this.data ? this.data["din.3"] : false;
        },
    },
    beforeUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
    beforeMount() {
        this.getBranchData()
    },
    async mounted() {
        //this.fetchRefillData();
        this.fetchData();
        this.fetchDuration();
        this.deviceTelemetry();
        //this.genReport();
        this.fetchMessages();
        this.setupRealtimeUpdates();
        this.getRefillData();
        
    },
    methods: {
        
        //Check Device Status
        checkDeviceStatus(){
            if(this.deviceBattery <= 75 || this.ExternalPower == 0){
                this.Error_Notification = true;
                this.color = 'red';
                this.errorText = 'Device Battery or External Power is below 20%';
            }
        },
        updateIoTData(data) {
            this.deviceBattery = data.batteryLevel;
            this.ExternalPower = data.externalPower;
            this.checkDeviceStatus();
        },
        //Fetch branch data from database
        async getBranchData() {
            try {
                const id = this.deviceId
                const branch = await api.getBranch(id);
                this.branch = branch.data;
            } catch (error) {
                console.error(error)
            }
        },
        gridPowerTemplate() {
            return Math.floor(Math.random() * (410 - 390 + 1)) + 390;
        },
        gridVoltageTemplate() {
            return Math.floor(Math.random() * (100 - 60 + 1)) + 60;
        },
        shortTimestamp(timestamp) {
            const date = new Date(timestamp * 1000); // Convert to milliseconds
            const options = {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        },

        //Device Messages for Reports
        async fetchMessages() {
            try {
                const response = await api.getMessages(this.deviceId);
                this.messages = response.data.result;
            } catch (error) {
                console.error("Error Fetching Device Message", error)
            }
        },
        //Date Selection for Report Generation
        onDateChange(dates) {
            if (dates && dates.length === 2) {
                this.selectedRange = dates.map(date => date.valueOf() / 1000); // Convert to Unix timestamp in seconds
                this.filterMessages();
            } else {
                this.filteredMessages = [];
            }
        },

        //Filter Selected Messages based on Selected date.
        filterMessages() {
            if (this.selectedRange.length === 2) {
                const [start, end] = this.selectedRange;
                this.filteredMessages = this.messages.filter(
                    message => message.begin >= start && message.end <= end
                );
                console.log('Messages from report api:', this.filteredMessages)

            }
        },

        async genReport() {
            try {
                const response = await api.getReport();
                this.reportPayload = response.data.result;
            } catch (error) {
                console.error(error);
            }
        },
        async deviceTelemetry() {
            const response = await api.deviceTelemetry(this.deviceId);
            this.branchName = response.data.result[0].telemetry;
        },
        async fetchDuration() {
            try {
                const response = await api.getFlespiData(this.deviceId);
                const interval = response.data.result;
                this.duration = interval[interval.length - 1]
                this.loading = false;
            } catch (error) {
                this.error = 'Error fetching data';
            }
        },
        convertToHHMMSS(seconds) {
            const hrs = Math.floor(seconds / 3600);
            const mins = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;
            return `${this.padToTwoDigits(hrs)}:${this.padToTwoDigits(mins)}:${this.padToTwoDigits(secs)}`;
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp * 1000); // Convert to milliseconds
            const options = {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        },
        padToTwoDigits(num) {
            return num.toString().padStart(2, '0');
        },
        //Fetch Location Data
        fetchData() {
            api.identLocationGetter(this.deviceId)
                .then(response => {
                    const data = response.data.result.map(item => ({
                        x: new Date((item['timestamp'] + 3600) * 1000), // Convert timestamp to milliseconds
                        y: item['escort.lls.value.1']
                    }));
                    this.series[0].data = data;
                    const sortedData = response.data.result.sort(
                        (a, b) => b['timestamp'] - a['timestamp']
                    );
                    this.data = sortedData[0];
                    console.log('Battery Level:', this.data['battery.level']);
                    console.log('External Power:', this.data['external.powersource.voltage']);
                    this.currentVolume = this.data['escort.lls.value.1'];
                    this.slider = this.data['escort.lls.value.1'];
                    this.tankLoading = false;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },

        //Fetch Refill Data
        async fetchRefillData() {
            try {
                const response = await api.refillNotification(this.deviceId);
                this.refill = response.data.result;
                console.log('Summary Data: ', response);
            } catch (error) {
                console.error("Error Fetching Device Message");
            }
        },
        async getRefillData() {
            try {
                console.log(this.deviceID);
                const response = await api.refillNotification(this.deviceId);
                this.items = response.data.result;
                this.unreadCount = this.items.length;
            } catch (error) {
                console.error('Error Fetching Data on Refill', error);
            }
        },
        setupRealtimeUpdates() {
            setInterval(this.fetchData, 300000); // Fetch new data every 5 minutes
        },
    },
    created() {
        setInterval(()=>{
            const simulateData = {
                batteryLevel: Math.floor(Math.random() * 76),
                externalPower: Math.floor(Math.random() * 0),
            };
            this.updateIoTData(simulateData);
        }, 5000)
    },
}
</script>
<style scoped>
.green-background {
    background-color: #92D050;
    color: white;
}

.red-background {
    background-color: red;
    color: white;
}

.rounded-lg {
    border-radius: 0.5rem;
    /* Equivalent to the 'lg' size in Tailwind CSS */
}

.table-responsive {
    overflow-x: auto;
}

.custom-table .custom-header {
    background-color: #92D050;
    color: #0E0856;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid white;
}

.custom-table .custom-row td {
    border-left: 2px solid #ddd;
    font-weight: bold;
}

.custom-table .custom-row td:first-child {
    border-left: none;
}
</style>