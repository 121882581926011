<template>
    <v-container fluid>
        <v-row v-if="!selectedLocation">
            <v-col class="d-none d-md-block" md="7">
                <MainMap />
            </v-col>
            <v-col md="5">
                <v-sheet color="#92D050" class="pa-5 rounded-xl fill-height">
                    <v-card color="#0E0856" class="rounded-lg">
                        <v-card-text class="d-flex align-center justify-space-between">
                            <!--DEVICE ERROR NOTIFICATION BUTTON-->
                            <v-sheet color="#0E0856" class="d-flex align-center">
                                <small class="d-none d-md-block">Device Error</small>
                                <v-dialog max-width="400" v-model="deviceAlert">
                                    <template v-slot:activator="{ props: activatorProps }">
                                        <v-btn v-bind="activatorProps" class="ml-2" variant="text" size="small">
                                            <v-icon size="20">mdi-chip</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card color="#0E0856" class="rounded-xl">
                                        <v-card-title>
                                            <div class="d-flex align-center justify-space-between">Device
                                                Notification
                                                <v-icon color="red">mdi-alert-outline</v-icon>
                                            </div>
                                        </v-card-title>
                                        <v-sheet min-height="50" class="pa-3 d-flex align-center">
                                            <span>There are no notifications</span>
                                        </v-sheet>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="warning" size="small" @click="deviceAlert = false">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-sheet>
                            <v-divider vertical />
                            <!--DRAIN NOTIFICATION BUTTON-->
                            <v-sheet color="#0E0856" class="d-flex align-center">
                                <small class="d-none d-md-block">Drain</small>
                                <v-dialog v-model="drain" max-width="400" persistent>
                                    <template v-slot:activator="{ props: activatorProps }">
                                        <v-btn v-bind="activatorProps" class="ml-2" variant="text" size="small">
                                            <v-badge color="error" :content="drainCount" v-if="drainCount > 0" overlap>
                                                <v-icon
                                                size="20">mdi-flash-alert-outline</v-icon>
                                            </v-badge>
                                            
                                        </v-btn>
                                    </template>
                                    <v-card color="#0E0856" class="rounded-xl">
                                        <v-card-title>
                                            <div class="d-flex align-center justify-space-between">Drain Alert
                                                Notification
                                                <v-icon color="red">mdi-alert-outline</v-icon>
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="scrollable-content">
                                            <v-sheet min-height="50" class="pa-3 d-flex align-center rounded-lg">
                                            <v-list>
                                                <v-list-item class="align-center" v-for="drain in drainData" :key="drain.timestamp">
                                                    Possible <span class="text-body font-weight-black" color="error">{{drain['drain_volume']}} </span> diesel drained at {{ drain['branch'] }} {{formatTimestamp(drain.start_time)}} <v-btn icon size="x-small" variant="text"><v-icon color="warning">mdi-alert</v-icon></v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-sheet>
                                        </v-card-text>
                                        <template v-slot:actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="warning" size="small" @click="drain = false">
                                                Close
                                            </v-btn>
                                        </template>
                                    </v-card>
                                </v-dialog>
                            </v-sheet>
                            <v-divider vertical />
                            <!--LOW FUEL NOTIFICATION BUTTON-->
                            <v-sheet color="#0E0856" class="d-flex align-center">
                                <small class="d-none d-md-block">Low Fuel</small>
                                <v-dialog v-model="low" max-width="400" persistent>
                                    <template v-slot:activator="{ props: activatorProps }">
                                        <v-btn v-bind="activatorProps" class="ml-2" variant="text" size="small"><v-icon
                                                size="20">mdi-gauge-empty</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card color="#0E0856" class="rounded-xl">
                                        <v-card-title color="red">
                                            <div class="d-flex justify-space-between">Low Diesel
                                                <v-icon color="red">mdi-alert-outline</v-icon>
                                            </div>
                                        </v-card-title>
                                        <v-sheet min-height="50" class="pa-3 d-flex align-center">
                                            <span>There are no notifications</span>
                                        </v-sheet>
                                        <template v-slot:actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="warning" size="small" @click="low = false">
                                                Close
                                            </v-btn>
                                        </template>
                                    </v-card>
                                </v-dialog>
                            </v-sheet>
                            <v-divider vertical />
                            <!--REFILL NOTIFICATION NOTIFICATION BUTTON-->
                            <v-sheet color="#0E0856" class="d-flex align-center">
                                <small class="d-none d-md-block">Service</small>
                                <v-dialog v-model="dialog" max-width="400" persistent>
                                    <template v-slot:activator="{ props: activatorProps }">
                                        <v-btn v-bind="activatorProps" class="ml-2" variant="text" size="small"><v-icon
                                                size="20">mdi-wrench-clock</v-icon></v-btn>
                                    </template>
                                    <v-card color="#0E0856" class="rounded-xl" max-width="400">
                                        <v-card-title class="d-flex align-center">
                                            <span>Maintenance Alert</span>
                                            <v-spacer></v-spacer>
                                            <v-icon size="small" class="ma-2" color="warning">mdi-fuel</v-icon>
                                        </v-card-title>
                                        <v-sheet min-height="50" class="pa-3 d-flex align-center">
                                            <span>There are no notifications</span>
                                        </v-sheet>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="warning" size="small" @click="dialog = false">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-sheet>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-3">
                        <LocationList @show-details="selectLocation" />
                    </v-card>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row v-else>
            <Location :selectedLocation="selectedLocation" :deviceId="deviceId" />
        </v-row>
        <v-fab location="bottom start" appear layout app v-if="selectedLocation" color="#92D050"
            icon="mdi-arrow-left-circle" @click="goBack"></v-fab>
    </v-container>
</template>
<script>
import Location from './LocationData.vue';
import LocationList from '../../components/LocationTable.vue';
import api from '@/services/api';
import MainMap from '@/components/MainMap.vue'
export default {
    components: {
        LocationList,
        Location,
        MainMap
    },
    data() {
        return {
            drainData: [],
            drainCount: 0,
            deviceAlert: false,
            selectedLocation: null,
            deviceId: null,
            dialog: false,
            drain: false,
            low: false,
            items: [],
            unreadCount: 0,
            branch: null,
        }
    },
    created() {

    },
    async mounted() {
        this.fetchRefillData();
        this.getDrainData();
    },
    methods: {
        //Get drain data
        async getDrainData() {
            try {
                const response = await api.getAllDrainNotification();
                this.drainData = response.data;
                this.drainCount = this.drainData.length;
                console.log(this.drainData)
            } catch (error) {
                console.error("Error Fetching Drain Data", error)
            }
        },
        async selectLocation(deviceId) {
            const response = await api.identLocationGetter(deviceId);
            this.selectedLocation = response.data;
            this.branch = response.data.result;
            this.deviceId = deviceId;
        },
        async fetchRefillData() {
            try {
                const response = await api.getRefillData();
                this.items = response.data.result;
                this.unreadCount = this.items.length
            } catch (error) {
                console.error('Error Fetching Data on Refill', error)
            }
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp * 1000); // Convert to milliseconds
            const options = {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        },
        goBack() {
            this.selectedLocation = null;
        },
        markAsRead() {
            this.unreadCount = 0;
        },
    }
}
</script>
<style scoped>
.scrollable-content {
  max-height: 400px; /* Set the desired height */
  overflow-y: auto; /* Makes the content scrollable */
}
</style>