<template>
  <v-sheet color="#92D050" class="pa-5 rounded-xl fill-height">
    <v-card id="map" style="width: 100%; height: 80vh;"></v-card>
  </v-sheet>
</template>

<script>

export default {
  name: "HereMap",
  computed: {
    branches() {
      return this.$store.getters.getBranches
    },
  },
  mounted() {
    /**
 * Boilerplate map initialization code starts below:
 */

    // initialize communication with the platform
    // In your own code, replace variable window.apikey with your own apikey
    var platform = new H.service.Platform({
      apikey: "YWsaVxOHSK3uFiDTkcWXrT45MV4WdkJHEHvFMlDKcPA",
      useCIT: true,
      app_code: "kETZbQTf9EDJCnbysY5B",
      useHTTPS: true
    });
    var defaultLayers = platform.createDefaultLayers();

    // initialize a map - this map is centered over Europe
    var map = new H.Map(
      document.getElementById("map"),
      defaultLayers.vector.normal.map,
      {
        center: { lat: 6.5294, lng: 3.3385 },
        zoom: 11.5,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener("resize", () => map.getViewPort().resize());

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // create default UI with layers provided by the platform
    var ui = H.ui.UI.createDefault(map, defaultLayers);

    // Now use the map as required...
    addInfoBubble(map);

    /**
     * Creates a new marker and adds it to a group
     * @param {H.map.Group} group       The group holding the new marker
     * @param {H.geo.Point} coordinate  The location of the marker
     * @param {String} html             Data associated with the marker
     */
    function addMarkerToGroup(group, coordinate, html) {      

      var logoStanbic = new H.map.Icon('https://res.cloudinary.com/netpoc-inc/image/upload/v1730464570/Stanbic_Logo_jlm2jn.png')

      var marker = new H.map.Marker(coordinate, {icon: logoStanbic});
      // add custom data to the marker
      marker.setData(html);
      group.addObject(marker);
    }

    /**
     * Add two markers showing the position of Liverpool and Manchester City football clubs.
     * Clicking on a marker opens an infobubble which holds HTML content related to the marker.
     * @param {H.Map} map A HERE Map instance within the application
     */
    function addInfoBubble(map) {
      var group = new H.map.Group();

      map.addObject(group);

      // add 'tap' event listener, that opens info bubble, to the group
      group.addEventListener(
        "tap",
        function (evt) {
          // event target is the marker itself, group is a parent event target
          // for all objects that it contains
          var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData(),
          });
          // show info bubble
          ui.addBubble(bubble);
        },
        false
      );

      addMarkerToGroup(
        group,
        { lat: 6.424177, lng: 3.427572 },
        '<div><strong>Ademola Adetokunbo Branch</strong></div>' +
        "<div>Tank Capacity: 5,000 liters</div>"
      );

      addMarkerToGroup(
        group,
        { lat: 6.599648, lng: 3.352452 },
        '<div><strong>Allen Branch</strong></div>' +
        "<div>Tank Size: 11,000 liters</div>"
      );
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 50hv;
  background: grey;
}
</style>