import axios from "axios";
//import store from "@/store"
// Create an Axios instance with default options
const apiClient = axios.create({
  baseURL: "https://flespi.io",
  headers: {
    "Content-Type": "application/json",
    Authorization: `FlespiToken WZ2ucUAAx1DXYJD45BwCylRjgUooHyT18KPBmjDc76eJJGUuot6PSVrkotHGIphD`,
  },
});

const rmsBackend = axios.create({
  baseURL: "https://rms-backend-6hdz.onrender.com/api",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
});

const rmsLocal = axios.create({
  baseURL: "https://rms-backend-6hdz.onrender.com/api",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
});

export default {
  //Drain All Notifications
  getAllDrainNotification() {
    return rmsLocal.get("/getdrain");
  },
  addClient(data) {
    return rmsBackend.post("/createclient", data);
  },
  getBranch(deviceId) {
    return rmsBackend(`/getlocation?deviceid=${deviceId}`);
  },
  getNumberOfLocations(rcNumber) {
    return rmsBackend.get(`/countBranches?rc_number=${rcNumber}`);
  },
  addLocation(data) {
    return rmsBackend.post("/addlocation", data);
  },
  async getClients() {
    try {
      const response = await rmsBackend.get("/getAllClients");
      return response;
    } catch (error) {
      console.log("Server error", error);
    }
  },
  //Get Group List
  getGroup() {
    return apiClient.get(
      "/gw/devices/groups.id=10348/telemetry/din.1,din.2,din.3"
    );
  },
  //Durations Calculator
  getFlespiData(ident) {
    return apiClient.get(
      `/gw/calcs/1704940/devices/configuration.ident=${ident}/intervals/all`
    );
  },
  getRefillData(ident) {
    return apiClient.get(
      `/gw/calcs/1704949/devices/configuration.ident=${ident}/intervals/all`
    );
  },
  // Getting locations based on group Id
  getAllDevices() {
    return apiClient.get(
      '/gw/devices/groups.id=10348/messages?data={"reverse":true,"count":1}'
    );
  },
  //Refill notification
  refillNotification(ident) {
    return apiClient.get(
      `/gw/calcs/1718762/devices/configuration.ident=${ident}/intervals/all`
    );
  },
  //Report Messages
  getMessages(ident) {
    return apiClient.get(
      `/gw/calcs/1719297/devices/configuration.ident=${ident}/intervals/all`
    );
  },

  //Weekly Report
  async getWeeklyReport(ident) {
    try {
      const currentTime = Math.floor(Date.now() / 1000); // Current timestamp in milliseconds
      const twentyFourHoursAgo = currentTime - 72 * 60 * 60; // 24 hours in milliseconds
      return apiClient.get(
        `/gw/calcs/1719297/devices/configuration.ident=${ident}/intervals/all?from=${twentyFourHoursAgo}&to=${currentTime}`
      );
    } catch (error) {
      console.log("Error fetching data from API");
    }
  },
  getLiveData() {
    return apiClient.get(
      "/gw/devices/5848395/telemetry/device.name,din.1,din.2,din.3,escort.lls.value.2"
    );
  },
  summary(deviceId) {
    return apiClient.get(`/gw/calcs/1707374/devices/${deviceId}/intervals/all`);
  },
  async getLocation(deviceId) {
    try {
      const response = await apiClient.get(
        `/gw/devices/${deviceId}/messages?data={"fields":"server.timestamp,escort.lls.value.2,escort.lls.value.3,din.1,din.2,din.3,timestamp,custom.param.306,device.name,position.latitude,position.longitude"}`
      );
      return response;
    } catch (err) {
      console.log("Error fetching data from API");
    }
  },
  deviceTelemetry(ident) {
    return apiClient.get(
      `/gw/devices/configuration.ident=${ident}/telemetry/device.name,din.1,din.2,din.3,escort.lls.value.2,ident`
    );
  },
  getChannelMessages() {
    return apiClient.get(
      `/gw/channels/1215718/messages?data={"fields":"server.timestamp,custom.param.306,ident,escort.lls.value.2,din.1,din.2,din.3,escort.lls.value.3,position.latitude,position.longitude"}`
    );
  },

  async identLocationGetter(ident) {
    try {
      const currentTime = Math.floor(Date.now() / 1000); // Current timestamp in milliseconds
      const twentyFourHoursAgo = currentTime - 60 * 60 * 60; // 24 hours in milliseconds
      const payload = await apiClient.get(
        `/gw/devices/configuration.ident=${ident}/messages?data={"fields":"server.timestamp,din.1,din.2,din.3,timestamp,custom.param.306,device.name,external.powersource.voltage,battery.level,position.latitude,escort.lls.value.1,position.longitude", "from":${twentyFourHoursAgo},"to":${currentTime}}`
      );      
      return payload;
    } catch (err) {
      console.log(err);
    }
  },
};
